import * as React from "react"
import { graphql } from 'gatsby';

// Plugins
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image'

// Application
import Layout from '../shell/layout'

// Styles
import { productsPage, divider, sdIntro, postIntro, postContent, productImgWrapper } from '../styles/products.module.css'

// Markup
const ProductsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout pageTitle={`${t("products")} | `} pagePath="products">
        <div className={productsPage}>
          {/* Carburate Sports Drink */}
          <section>
            <StaticImage
              src="../images/its-time-to-fuel-up.jpg"
              alt="It's time to fuel up"
              placeholder="none"
            />

            <div className={divider}></div>

            <div className={sdIntro}>
              <div className={productImgWrapper}>
                <StaticImage
                  src="../images/carburate-sports-drink.webp"
                  alt="Carburate Sports Drink"
                  placeholder="none"
                />
              </div>
              
              <div>
                <h1>CARBURATE SPORTS DRINK</h1>
                <h2>{t("carburate-sd-subtitle")}</h2>
                <ul>
                  <li>{t("carburate-sd-characteristics-1")}</li>
                  <li>{t("carburate-sd-characteristics-2")}</li>
                  <li>{t("carburate-sd-characteristics-3")}</li>
                  <li>{t("carburate-sd-characteristics-4")}</li>
                </ul>
                <h3>{t("flavors")} - 750g (25 portions)</h3>
                <p>{t("carburate-sd-flavors-1")}, {t("carburate-sd-flavors-2")}, {t("carburate-sd-flavors-3")}, {t("carburate-sd-flavors-4")}</p>
                <h3>{t("flavors")} - 960g (32 portions)</h3>
                <p>{t("carburate-sd-flavors-5")}</p>
              </div>
            </div>

            <div className={postContent}>
              <p>{t("carburate-sd-p1")}</p>
              <p>{t("carburate-sd-p2")}</p>
              <p>{t("carburate-sd-p3")}</p>
              <p>{t("carburate-sd-p4")}</p>

              <h3>{t("ingredients")}</h3>
              <p>{t("carburate-sd-ingredients-p1")}</p>

              <h3>{t("suggested-usage")}</h3>
              <p>{t("carburate-sd-suggested-usage-p1")}</p>
            </div>
          </section>

          {/* Carburate Post */}
          <section>
            <StaticImage
              src="../images/goutez-la-victoire.jpg"
              alt="Goutez la victoire"
              placeholder="none"
            />

            <div className={divider}></div>

            <div className={postIntro}>
              <div className={productImgWrapper}>
                <StaticImage
                  src="../images/carburate-post.webp"
                  alt="Carburate Post"
                  placeholder="none"
                />
              </div>

              <div>
                <h1>CARBURATE POST</h1>
                <h2>{t("carburate-post-subtitle")}</h2>
                <ul>
                  <li>{t("carburate-post-characteristics-1")}</li>
                  <li>{t("carburate-post-characteristics-2")}</li>
                  <li>{t("carburate-post-characteristics-3")}</li>
                  <li>{t("carburate-post-characteristics-4")}</li>
                </ul>
                <h3>{t("flavors")} - 908g (30 portions)</h3>
                <p>{t("carburate-post-flavors-1")}</p>
              </div>
            </div>

            <div className={postContent}>
              <p>{t("carburate-post-p1")}</p>
              <p>{t("carburate-post-p2")}</p>
              
              <h3>{t("ingredients")}</h3>
              <p>{t("carburate-post-ingredients-p1")}</p>

              <h3>{t("suggested-usage")}</h3>
              <p>{t("carburate-post-suggested-usage-p1")}</p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default ProductsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;